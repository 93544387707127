import { useState, useEffect, useMemo, useContext } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import VerifyEmail from "layouts/verify-email";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import ResetCover from "layouts/authentication/reset-password/cover";
import AffiliateVideo from "layouts/pages/affiliate/affiliateVideo";
import SignInCover from "layouts/authentication/sign-in/cover";
import Register from "layouts/authentication/register";
// RTL plugins
import RegisterAndRedeem from 'layouts/pages/appsumo/Registration'
import AuthCallbacks from 'layouts/authentication/AuthCallbacks'
import ActiveLifetimeAccess from 'layouts/pages/appsumo/ActiveLifetimeAccess'
import Subscription from "layouts/authentication/subscription"
import Messages from "layouts/pages/support/components/Messages";
import createCache from "@emotion/cache";
import CouponDetails from "layouts/pages/stripe/coupons/components/Details";

import routes from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

import "./styles/globals.css";
// Images
import logo from "assets/crmimages/logo.png";
import Booking from "layouts/pages/booking";
import { Toaster } from "react-hot-toast";
import AuthContext from "context/Authcontext";
import PrivacyPolicies from "layouts/pages/privacy/PrivacyPolicies";
import TermsAndConditions from "layouts/pages/termsandcondition/TermsAndConditions";
import AffiliatePolicies from "layouts/pages/affiliatePolicies/AffiliatePolicies";
import Payment from "layouts/authentication/payment";
import RefreshOnboardingUrl from "layouts/pages/affiliate/refreshOnboardingUrl";
import ActivityLog from "layouts/pages/activityLog";
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const { isAuthenticated, profileImage, isPro } = useContext(AuthContext);

  useEffect(() => {}, [isAuthenticated, profileImage]);

  const shouldShowSidebar = () => {
    let showSidebar = true;
    const pagesToHide = [
      "booking",
      "termsofuse",
      "privacypolicy",
      "affiliatepolicies",
      "affiliate",
      "register",
      "auth",
      "activatelifetimeaccess",
    ];
    showSidebar = !pagesToHide.some((page) => window.location.pathname.includes(page));
    if (window.location.pathname.startsWith("/subscription")) {
      showSidebar = false;
    }
    return showSidebar;
  };

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <Toaster />
      {layout === "dashboard" && (
        <>
          {shouldShowSidebar() && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? logo : logo}
                brandName="Skoop"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
            </>
          )}
        </>
      )}
      <Routes>
        
        {getRoutes(routes)}
        <Route path="*" element={<SignInCover />} /> 
        <Route path="/authentication/register" element={<Register />} />
        <Route path="/authentication/reset-password" element={<ResetCover />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/affiliate" element={<AffiliateVideo/>} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/affiliate/onboarding" element={<AffiliateVideo />} />
        <Route path="/privacypolicy" element={<PrivacyPolicies />} />
        <Route path="/termsofuse" element={<TermsAndConditions />} />
        <Route path="/affiliatepolicies" element={<AffiliatePolicies />} />
        <Route path="/appsumoregisterandredeem" element={<RegisterAndRedeem />} />
        <Route path="/auth/linkedin/callback" element={<AuthCallbacks />} />
        <Route path="/auth/google/callback" element={<AuthCallbacks />} />
        <Route path="/auth/microsoft/callback" element={<AuthCallbacks />} />
        <Route path="/activatelifetimeaccess" element={<ActiveLifetimeAccess />} />
        <Route path="/" element={<ActiveLifetimeAccess />} />
        <Route path="/subscription" element={<Subscription />} /> 
        <Route path="/payment" element={<Payment />} />  
        <Route path="/affiliateRefresh" element={<RefreshOnboardingUrl />} />   
        <Route path="/activity-log" element={<ActivityLog />} />   
        <Route path="/pages/support/:id" element={<Messages />} />
        <Route path="/pages/stripe/coupons/:id" element={<CouponDetails />} />
      </Routes>
    </ThemeProvider>
  );
}
