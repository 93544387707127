import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MDBox from "components/MDBox";

import PropTypes from "prop-types";

const MDTableBodyCell = ({ rows, columns, align, padding }) => {
  return (
    <>
      {rows &&
        rows.map((row, rowIndex) => (
          <TableRow hover tabIndex={-1} key={rowIndex} className="table-cell">
            {columns?.map((column, columnIndex) => {
              const value = row[column.accessor];
              return (
                <TableCell
                  component="td"
                  width="auto"
                  minWidth={column.minWidth || "150px"}
                  className="table-col-cell"
                  align={column.align}
                  id={column.accessor}
                  scope="row"
                  variant="body"
                >
                  <MDBox
                    display="inline-block"
                    width="max-content"
                    color="text"
                    px={1}
                    sx={{ verticalAlign: "middle" }}
                  >
                    {column.Cell({ value })}
                  </MDBox>
                </TableCell>
              );
            })}
          </TableRow>
        ))}
    </>
  );
};

MDTableBodyCell.defaultProps = {
  align: "left",
  padding: "normal",
};

MDTableBodyCell.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  align: PropTypes.oneOf(["left", "right", "center"]),
  padding: PropTypes.oneOf(["none", "normal"]),
};

export default MDTableBodyCell;
